<template>
  <div class="formuleEssentielle">
    <Loader/>
    <video autoplay muted loop id="bg3">
      <source src="../assets/bg3.mp4" type="video/mp4">
      Your browser does not support HTML5 video.
    </video>

    <h2>Formule Essentielle</h2>

    <p class="about">Retrouvez ci dessous quelques modèles personnalisables de la formule économique :</p>

    <div class="conteneur">
      <div class="masonry-eco">
          <div class="item" v-for="template in templates" :key="template.id" :onmouseover=template.hoverColor onmouseout="this.style.backgroundColor='';" >
            <img :src="template.image" class="card-img-top" alt="...">
            <h5>{{template.name}}</h5>
            <div class="note">{{template.description}}</div>
            <a :href="template.lien" :class="template.classBouton" target="_blank">Voir la demo</a>
          </div>
        </div>
    </div>
  </div>
</template>

<style scoped>
#bg3 {
  position: fixed;
  right: 0;
  top: 0px;
  min-width: 100%;
  min-height: 100%;
  z-index: -1000;
}

h2{
  color: #fff;
  padding: 120px 0 0 70px;
  font-size: 2.5em;
}

.about{
  color: #fff;
  font-size: 22px;
  text-align: center;
  margin: 80px auto 30px;
}

.note {
  white-space: pre-line;
}

.masonry-eco {
  margin: 5px auto 0 auto;
  max-width: 80%;
  column-gap: 1.5em;
  padding-bottom: 50px;
}

.item img{
  width: 100%;
}

.item h5{
  color: white;
  font-size: 25px;
}

.item a{
  width: 80%;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  background-color: transparent;
  border: 1px solid;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: 0.2s;
  text-decoration: none;
}

.dark {
  color: #fff;
  border-color: #606264;
}

.dark:hover {
  color: #fff;
  background-color: #606264;
  border-color: #606264;
}

.primary {
  color: #007bff;
  border-color: #007bff;
}

.primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.warning {
  color: #ffc107;
  border-color: #ffc107;
}

.warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.danger {
  color: #dc3545;
  border-color: #dc3545;
}

.danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

/* The Masonry Brick */
.masonry-eco .item {
  padding: 1em;
  margin: 0 0 1.5em;
  border: solid rgb(180, 177, 177) 2px!important;
  transition : 0.5s;
  border-radius: 5px;
  text-align: center;
}

/* Masonry on medium-sized screens */
@media only screen and (min-width: 768px) {
  .masonry-eco {
    column-count: 2;
  }
}
</style>

<script>
import Loader from '@/components/Loader.vue'

export default {
  components: {
    Loader
  },
  name: 'FormuleEssentielle',
  data () {
    return {
      templates: {
        'Restaurant à sushi': {
          name: 'Restaurant à sushi',
          description: '',
          image: require('../assets/temprestoeco.png'),
          lien: '/templates_eco/template_resto/',
          hoverColor: "this.style.backgroundColor='rgba(100, 100, 100, 0.4)';",
          classBouton: 'dark'
        },
        'Entreprise de BTP': {
          name: 'Entreprise de BTP',
          description: '',
          image: require('../assets/tempbtpeco.png'),
          lien: '/templates_eco/template_btp/',
          hoverColor: "this.style.backgroundColor='rgba(255, 193, 7, 0.4)';",
          classBouton: 'warning'
        },
        'Gite au bord de la mer': {
          name: 'Gîte au bord de la mer',
          description: '',
          image: require('../assets/tempgiteeco.png'),
          lien: '/templates_eco/template_gite/',
          hoverColor: "this.style.backgroundColor='rgba(0, 95, 213, 0.4)';",
          classBouton: 'primary'
        },
        'Aide à la personne': {
          name: 'Aide à la personne',
          description: '',
          image: require('../assets/tempaideeco.png'),
          lien: '/templates_eco/template_aide/',
          hoverColor: "this.style.backgroundColor='rgba(255, 0, 0, 0.4)';",
          classBouton: 'danger'
        }
      }
    }
  },
  mounted () {
    document.querySelector('.navCheckbox').checked = false
    setTimeout(
      function () {
        document.querySelector('#loader').style.display = 'none'
      }, 2300)
    window.scrollTo(0, 0)
  }
}
</script>
